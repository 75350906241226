<template>
  <div class="importTemplate-container">
    <form-panel class="form-container" ref="formPanel" :hasHeader="hasHeader">
      <el-form-item
        :label="label"
        :rules="[
          {
            required: false,
            message: '',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="modelIconPic"
      >
        <v-button text="下载" @click="downloadTemplate"></v-button>
      </el-form-item>
      <el-form-item
        label="上传模板"
        :rules="[
          {
            required: false,
            message: '请上传应用图标',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="modelIconPic"
      >
        <v-fileUpload
          :otherParams="otherParams"
          :limit="1"
          :imgUrls.sync="form.modelIconPic"
          :uploadImgUrl="uploadImgUrl"
        />
      </el-form-item>
      <template #saveBeforeSlot>
        <v-button text="关闭" @click="closeForm" v-if="showCloseBtn">
        </v-button>
      </template>
    </form-panel>
  </div>
</template>
<script>
import { downloadHelper } from "@/utils/func";

// @group 业务组件
// @title ImportTemplate 导入
export default {
  name: "import-template",
  components: {},
  props: {
    otherParams: {
      type: Object,
      default() {
        return {};
      },
    },
    label: {
      type: String,
      default: "",
    },
    showCloseBtn: {
      type: Boolean,
      default: true,
    },
    // 是否显示表单头部返回按钮
    hasHeader: {
      type: Boolean,
      default: true,
    },
    // 导入Url
    uploadImgUrl: String,
    // 下载模板Url
    downloadUrl: String,
    // 标题
    title: String,
    // 下载按钮文字描述
    downloadText: {
      type: String,
      // `'下载导入模板'`
      default: "下载导入模板",
    },
    // 上传文件大小 M
    maxFileSize: {
      type: Number,
      // `'10'`
      default: 10,
    },
    // 是否自动上传
    autoUpload: {
      type: Boolean,
      // `'false'`
      default: false,
    },
    // label
    uploadLabel: {
      type: String,
      // `'下载导入模板'`
      default: "请选择导入文件",
    },
    // 下载模板方式
    downloadType: {
      type: String,
      // `'default'`  / `'key'`
      default: "default",
    },
    // 保存url, autoUpload为true时必填
    submitUrl: String,
    //  上传时附带的额外参数
    extraData: {
      type: Object,
      // `{}`
      default: () => {
        return {
          type: "",
        };
      },
    },
    remarkLabel: {
      type: String,
      default: "备注",
    },
    // 导入前的回调函数
    saveBefore: Function,
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    closeForm() {
      this.$router.go(-1);
    },
    previous() {
      this.$router.go(-1);
    },
    async saveFile() {},

    storageChange(item) {
      this.storageList = item;
    },
    async onError(response, file, fileList) {},
    downloadTemplate() {
      if (this.downloadType === "default") {
        this.downloadUrl && downloadHelper.downloadByLocation(this.downloadUrl);
      } else if (this.downloadType === "key") {
        downloadHelper.downloadByApi(
          {
            exportUrl: this.downloadUrl,
          },
          (res) => {
            res.data &&
              res.data.filePath &&
              downloadHelper.downloadByLocation(res.data.filePath);
          }
        );
      }
    },
    onSuccess(response, file, fileList) {
      this.$emit("savingRequestFinished");
      this.response = response;
    },
  },
};
</script>
<style lang="less" scoped>
.importTemplate-container {
  // box-sizing: border-box;
  // height: 100%;
}
</style>
