<template>
  <div class="page">
    <import-template
      style="box-sizing: border-box; height: 100%"
      :uploadImgUrl="uploadImgUrl"
      :downloadUrl="downloadUrl"
      :showCloseBtn="true"
    ></import-template>
  </div>
</template>

<script>
import importTemplate from "@/components/bussiness/import-template/index";
export default {
  name: "canteenImport",
  data() {
    return {
      downloadUrl: "/static/excel/套餐菜品模板.xlsx",
      uploadImgUrl: "/gateway/hc-health/meal/import",
    };
  },
  components: {
    importTemplate,
  },
  mounted() {
    this.$setBreadList("套餐菜品导入");
  },
  methods: {
    submitSuccess(data) {
      return true;
    },

    submitBefore() {
      return true;
    },

    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>
